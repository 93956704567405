import React from 'react'

export default () => (
  <div>
    <h1>I worked at a Kindergarten</h1>
    <p>
      All male citizens in Austria have to undergo mandatory military service for 6 months, or serve alternative civilian service for 9 months. I chose the latter.
    </p>
    <blockquote cite="https://en.wikipedia.org/wiki/Military_service#Austria">
      “Austria has mandatory military service for all able bodied male citizens up to 35 years of age. Since 2006, the period of service has been six months. Conscientious objectors can join the civilian service (called Zivildienst) for nine months.” – Wikipedia
    </blockquote>
    <blockquote cite="https://en.wikipedia.org/wiki/Zivildienst">
      “The Zivildienst in Austria is provided as an alternative for conscientious objectors to the draft for military service. It is served for nine months, three months longer than the military service. Participants can choose one of several organisations (mainly NGOs) at which to serve. Most popular choices for compulsory service personnel are working for the ambulance services (usually transporting non-emergency patients to and from hospital) and nursing homes. Other options include serving at hospitals, charity organizations or in several ministries.” – Wikipedia
    </blockquote>
    <p>
      Options for Zivildienst (alternative civilian service) also include working at kindergartens. I opted for working with children, as it sounded better than joining the military; and it was. <br/>
      I definitely learned many lessons which I would have otherwise missed. 
    </p>
  </div>
)
